<template>
  <ion-page>
    <ion-content :fullscreen="true" :scroll-events="true">
      <div id="container">
        <ion-row style="margin-top: 16px; margin-left: 16px">
          <ion-text style="font-size: 137%; font-weight: semi-bold;">
            Área Sembrada
          </ion-text>
        </ion-row>
        <ion-card color="light">
          <ion-card-content color="light">
            <ion-tabs>
              <ion-tab-bar slot="top" :selected-tab="tabOption">
                <ion-tab-button
                  @click="tabOption = 'Cultivo'"
                  tab="Cultivo"
                  style="font-weight: bold"
                  selected
                >
                  Según Cultivo
                </ion-tab-button>
                <ion-tab-button
                  @click="tabOption = 'Antecesor'"
                  tab="Antecesor"
                  style="font-weight: bold"
                >
                  Según Antecesor
                </ion-tab-button>
                <ion-tab-button
                  @click="tabOption = 'Densidad'"
                  tab="Densidad"
                  style="font-weight: bold"
                >
                  Según Densidad
                </ion-tab-button>
              </ion-tab-bar>
            </ion-tabs>

            <br />
            <br />

            <AreaSembradaPorCultivoVariedadHa v-if="tabOption === 'Cultivo'" />
            <AreaSembradaPorCultivoAntecesor v-if="tabOption === 'Antecesor'" />
            <AreaSembradaPorDensidad v-if="tabOption === 'Densidad'" />
          </ion-card-content>
        </ion-card>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonText,
  IonRow,
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonContent,
  IonPage,
  IonCard,
  IonCardContent
} from "@ionic/vue";
import { mapGetters } from "vuex";
import AreaSembradaPorCultivoVariedadHa from "@/components/informes/AreaSembradaPorCultivoVariedadHa.vue";
import AreaSembradaPorCultivoAntecesor from "@/components/informes/AreaSembradaPorCultivoAntecesor.vue";
import AreaSembradaPorDensidad from "@/components/informes/AreaSembradaPorDensidad.vue";

export default {
  components: {
    IonContent,
    IonText,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonPage,
    IonRow,
    IonCard,
    IonCardContent,
    AreaSembradaPorCultivoVariedadHa,
    AreaSembradaPorCultivoAntecesor,
    AreaSembradaPorDensidad
  },
  middleware: ["authenticated", "auth_cliente"],
  data: () => ({
    tabOption: ""
  }),

  computed: {
    ...mapGetters(["isAdmin"])
  },

  async mounted() {
    this.$nextTick(() => {
      this.tabOption = "Cultivo";
    });
  },

  methods: {
    volver() {
      /*if (this.isAdmin) {
        this.$router.replace({ name: "admin-productores" });
      } else {
        this.$router.replace({ name: "persona-lotes" });
      }*/
      this.$router.back();
    }
  }
};
</script>

<style scoped>
ion-tabs {
  max-height: 150px;
}
.margen8px {
  margin-left: 8px;
}

.margen8pxArriba {
  margin-top: 8px;
}

.leftMargin2vh {
  margin-left: 2vh;
}

.imgFocus {
  cursor: pointer;
}

@media only screen and (min-width: 1024px) {
  #container {
    width: 50% !important;
    overflow: hidden !important;
    margin-left: 43px;
  }
}

ion-tab-button {
  --background: rgba(var(--ion-color-primary-rgb), 0.3);
  --ripple-color: var(--ion-color-primary);
  --color: var(--ion-color-dark);
  --color-selected: var(--ion-color-primary);
}

ion-tab-button.tab-selected {
  --background: var(--ion-color-primary) !important;
  --ripple-color: var(--ion-color-light);
  --color-selected: var(--ion-color-dark);
}

ion-tab-bar {
  justify-content: center;
}

ion-card {
  margin-top: 16px;
}
</style>
