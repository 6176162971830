<template>
  <ion-row class="ion-margin-top">
    <ion-col size="auto">
      <ion-label style="margin-left: 8px">
        Filtrar por:
      </ion-label>
      <ion-radio-group v-model="filtrarPor">
        <ion-row class="ion-align-items-center">
          <ion-col size="auto">
            <ion-label style="margin-right: 8px">Regional</ion-label>
            <ion-radio
              @click="filtrarPor = 'Regional'"
              value="Regional"
            ></ion-radio>
          </ion-col>
          <ion-col size="auto">
            <ion-label style="margin-right: 8px">Nodo</ion-label>
            <ion-radio @click="filtrarPor = 'Nodo'" value="Nodo"></ion-radio>
          </ion-col>
        </ion-row>
      </ion-radio-group>
    </ion-col>
    <ion-col v-if="filtrarPor === 'Regional'">
      <ion-item lines="full">
        <ion-label position="floating" class="ion-margin-top">
          Regional
        </ion-label>
        <ion-select
          :value="selectedRegional"
          @ionChange="selectedRegional = $event.target.value"
        >
          <ion-select-option
            v-for="reg in regionales"
            :value="reg"
            :key="reg._id"
          >
            {{ reg.nombre }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </ion-col>
    <ion-col v-if="filtrarPor === 'Nodo'">
      <ion-item lines="full">
        <ion-label position="floating" class="ion-margin-top">
          Nodo
        </ion-label>
        <ion-select
          :value="selectedNodo"
          @ionChange="selectedNodo = $event.target.value"
        >
          <ion-select-option v-for="n in nodos" :value="n" :key="n">
            {{ n }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </ion-col>
  </ion-row>
  <ion-row class="ion-align-items-center">
    <ion-col>
      <ion-item lines="full">
        <ion-label position="floating" class="ion-margin-top">
          Campañas
        </ion-label>
        <ion-select
          :value="selectedCampania"
          @ionChange="selectedCampania = $event.target.value"
        >
          <ion-select-option
            v-for="camp in campanias"
            :value="camp"
            :key="camp._id"
          >
            {{ camp.nombre }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </ion-col>
  </ion-row>
  <ion-row class="ion-align-items-center">
    <ion-col>
      <ion-item lines="full">
        <ion-label position="floating" class="ion-margin-top">
          Cultivos
        </ion-label>
        <ion-select
          :value="selectedCultivo"
          @ionChange="selectedCultivo = $event.target.value"
          :disabled="!this.selectedCampania"
        >
          <ion-select-option
            v-for="cult in cultivos"
            :value="cult"
            :key="cult._id"
          >
            {{ cult.nombre }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </ion-col>
    <ion-col size="auto">
      <ion-button color="tertiary" @click="updateChart">
        Ver
      </ion-button>
    </ion-col>
  </ion-row>
  <div v-if="selectedCampania && selectedCultivo && displayChart && isData">
    <LineChart
      :titulo="
        'Área Sembrada de ' +
          this.selectedCultivo.nombre +
          ' por densidad de siembra'
      "
      :labels="densidades"
      :dataLabel="dataLabel"
      :dataValues="dataValues"
      label="Densidad (Plantas/m2)"
      backgroundColor="rgba(26, 55, 239, 0.5)"
    />
  </div>
  <div v-if="displayChart && !isData">
    <ion-text style="margin-top: 16px; margin-left: 16px">
      No hay datos de Densidad coincidentes con la búsqueda
    </ion-text>
  </div>
  <ion-button
    @click="volver"
    class="ion-margin-top ion-float-end ion-margin-bottom"
    color="primary"
    v-if="!isAdminAapresid"
  >
    Volver
  </ion-button>
  <ion-row class="ion-align-items-center ion-margin-top" v-if="isAdminAapresid">
    <ion-col>
      <ion-button @click="generateExcel" class="ion-float-start" fill="clear">
        <ion-icon
          :md="icons.excel"
          :ios="icons.excel"
          color="secondary"
          size="large"
        ></ion-icon>
      </ion-button>
    </ion-col>
    <ion-col size="auto">
      <ion-button @click="volver" class="ion-float-end" color="primary">
        Volver
      </ion-button>
    </ion-col>
  </ion-row>
</template>

<script>
import {
  IonRow,
  IonCol,
  IonRadio,
  IonRadioGroup,
  IonItem,
  IonLabel,
  IonButton,
  alertController,
  IonSelect,
  IonSelectOption,
  IonText,
  IonIcon
} from "@ionic/vue";
import { mapGetters, mapState } from "vuex";
import http from "@/api/http";
import { Regionales, Campanias, CampaniaCultivos } from "@/api";
import LineChart from "@/components/charts/LineChart.vue";
import dateFormat from "@/utils/dateFormat";
import { readerOutline } from "ionicons/icons";

export default {
  components: {
    IonItem,
    IonButton,
    IonRadio,
    IonRadioGroup,
    IonRow,
    IonCol,
    IonLabel,
    IonSelect,
    IonSelectOption,
    LineChart,
    IonText,
    IonIcon
  },
  middleware: ["authenticated", "auth_cliente"],
  data: () => ({
    selectedRegional: { _id: "0", nombre: "" },
    selectedNodo: "",
    selectedCampania: undefined,
    selectedCultivo: undefined,
    filtrarPor: "Regional",
    displayChart: false,
    dataLabel: "Hectáreas",
    dataValues: [],
    regionales: [],
    campanias: [],
    cultivos: [],
    densidades: [],
    nodos: [
      "Todos",
      "Litoral",
      "Centro",
      "Sur",
      "O.M.",
      "Oeste",
      "Internacional"
    ],
    icons: {
      excel: readerOutline
    }
  }),

  computed: {
    ...mapGetters(["isAdmin"]),
    ...mapState(["admin_user"]),
    isAdminAapresid() {
      return this.isAdmin && this.admin_user.roles[0] === "ADMIN_AAPRESID";
    },
    isData() {
      return this.densidades.length ? true : false;
    }
  },

  watch: {
    filtrarPor() {
      this.selectedRegional = { _id: "0", nombre: "" };
      this.selectedNodo = "";
    },
    async selectedCampania() {
      this.cultivos = await this.getCultivos();
    }
  },

  async mounted() {
    await this.dataSync();
  },

  methods: {
    async dataSync() {
      this.regionales = await this.getRegionales();
      this.campanias = await this.getCampanias();
      this.$nextTick(() => {
        this.selectedCampania = this.campanias.find(c => c.esActual === true);
      });
    },

    async updateChart() {
      if (this.selectedCampania && this.selectedCultivo) {
        const { labels, values } = await this.getLineChartData();
        this.densidades = labels;
        this.dataValues = values;
        this.displayChart = true;
      } else {
        const alert = await alertController.create({
          header: "Error",
          message: "Debe elegir una Campaña y un Cultivo para poder continuar.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
    },

    async getCultivos() {
      const { data, error } = await CampaniaCultivos.get({
        query: {
          campania: this.selectedCampania._id,
          cultivo: { $type: "objectId" }
        },
        populate: "cultivo",
        sort: { nombre: 1 }
      });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo cultivos.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        return alert.present();
      }

      const cultivos = data
        .map(d => {
          return {
            nombre:
              d.cultivo && d.cultivo.nombre !== undefined
                ? d.cultivo.nombre
                : "Otros"
          };
        })
        .filter(c => c.nombre !== "Otros");

      const noDups = arr => {
        const unique = arr.filter((obj, index, self) => {
          return self.findIndex(c => c.nombre === obj.nombre) === index;
        });
        return unique;
      };

      return noDups(cultivos);
    },

    async getRegionales() {
      const { data, error } = await Regionales.get({
        sort: { nombre: 1 }
      });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo regionales.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        return alert.present();
      }
      data.unshift({ _id: "0", nombre: "Todas" });
      return data;
    },

    async getCampanias() {
      const { data, error } = await Campanias.get({
        sort: { nombre: 1 }
      });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo Campañas.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        return alert.present();
      }
      return data;
    },

    async generateExcel() {
      if (!this.selectedCampania) {
        const alert = await alertController.create({
          header: "Error",
          message: "Debe elegir una Campaña para poder continuar.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      } else {
        const { data } = await http.get("/api/area-sembrada-get-xlsx", {
          params: {
            campania: this.selectedCampania,
            regional: { _id: "0", nombre: "" },
            nodo: "Todos",
            cultivo: undefined
          }
        });
        const mimeType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const campaniaTxt = this.selectedCampania
          ? this.selectedCampania.nombre + "_"
          : "";
        const fileName =
          "Informe_Area_Sembrada_" +
          campaniaTxt +
          "-_" +
          dateFormat(new Date(), "dd-MM-yyyy") +
          "_-_" +
          dateFormat(new Date(), "HH-mm") +
          ".xlsx";
        fetch(`data:${mimeType};base64,${data}`)
          .then(res => res.blob())
          .then(blob => new File([blob], fileName, { type: mimeType }))
          .then(doc => {
            const url = (window.URL || window.webkitURL).createObjectURL(doc);
            let a = document.createElement("a");
            a.href = url;
            a.download = fileName;
            a.dataset.downloadurl = `${mimeType}:${a.download}:${a.href}`;
            document.body.appendChild(a);
            a.click();
            a.remove();
            return url;
          });
      }
    },

    async getLineChartData() {
      const { data, error } = await http.get(
        "/api/area-sembrada-densidad-data",
        {
          params: {
            campania: this.selectedCampania,
            regional: this.selectedRegional,
            nodo: this.selectedNodo,
            cultivo: this.selectedCultivo
          }
        }
      );
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error calculando las Hectáreas por Cultivo.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        return alert.present();
      }
      const labels = data.map(d => d.densidad);
      const values = data.map(d => d.hectareas);
      return { labels, values };
    },

    volver() {
      this.$router.back();
    }
  }
};
</script>
