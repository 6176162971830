<template>
  <div>
    <h2 style="margin-top:16px">
      {{ titulo }}
    </h2>
    <div :id="'canvas-piechart-container' + chartIdNumber"></div>
  </div>
</template>

<script>
import { Chart } from "chart.js";

export default {
  props: {
    chartIdNumber: {
      type: Number,
      default: 0
    },
    labels: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ""
    },
    titulo: { type: String, default: "" },
    dataLabel: { type: String, default: "" },
    dataValues: {
      type: Array,
      default: () => []
    },
    backgroundColor: { type: String, default: "" }
  },

  computed: {
    randomRgbaColors() {
      const colors = [];
      for (let i = 0; i < this.labels.length; i++) {
        let rgb = [];
        for (let j = 0; j < 3; j++) {
          rgb.push(Math.floor(Math.random() * 255));
        }
        colors.push("rgba(" + rgb.join(",") + " , 1)");
      }
      return colors;
    }
  },

  watch: {
    labels() {
      this.updateData();
    },
    dataValues() {
      this.updateData();
    }
  },
  mounted() {
    this.updateData();
  },

  methods: {
    updateData() {
      //const ctx = document.getElementById("PieChart" + this.titulo);
      new Chart(this.resetCanvas(), {
        type: "pie",
        data: {
          labels: this.labels,
          datasets: [
            {
              label: this.dataLabel,
              backgroundColor: this.randomRgbaColors,
              data: this.dataValues
            }
          ]
        },
        options: {
          responsive: false,
          showTooltips: true,
          tooltips: {
            enabled: true,
            mode: "index",
            intersect: true,
            callbacks: {
              label: function(tooltipItem, data) {
                return (
                  data.labels[tooltipItem.index] +
                  ": " +
                  data.datasets[0].data[tooltipItem.index] +
                  " Ha (" +
                  Math.round(
                    (data.datasets[0].data[tooltipItem.index] /
                      data.datasets[0].data.reduce((a, b) => a + b, 0)) *
                      100
                  ) +
                  "%)"
                );
              }
            }
          },
          hover: {
            mode: "nearest",
            intersect: true
          },
          scales: {
            yAxes: [
              {
                display: true,
                ticks: {
                  suggestedMin: 0,
                  beginAtZero: true
                }
              }
            ],
            xAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: this.label
                }
              }
            ]
          }
        }
      });
    },

    resetCanvas() {
      let container = document.getElementById(
        "canvas-piechart-container" + this.chartIdNumber
      );
      let canvas = document.createElement("canvas");
      canvas.style.width = "100%";
      canvas.style.height = "50vh";
      canvas.id = `PieChart ${this.titulo}`;
      container.innerHTML = "";
      container.append(canvas);
      return canvas;
    }
  }
};
</script>
