<template>
  <ion-row class="ion-margin-top">
    <ion-col size="auto">
      <ion-label style="margin-left: 8px">
        Filtrar por:
      </ion-label>
      <ion-radio-group v-model="filtrarPor">
        <ion-row class="ion-align-items-center">
          <ion-col size="auto">
            <ion-label style="margin-right: 8px">Regional</ion-label>
            <ion-radio
              @click="filtrarPor = 'Regional'"
              value="Regional"
            ></ion-radio>
          </ion-col>
          <ion-col size="auto">
            <ion-label style="margin-right: 8px">Nodo</ion-label>
            <ion-radio @click="filtrarPor = 'Nodo'" value="Nodo"></ion-radio>
          </ion-col>
        </ion-row>
      </ion-radio-group>
    </ion-col>
    <ion-col v-if="filtrarPor === 'Regional'">
      <ion-item lines="full">
        <ion-label position="floating" class="ion-margin-top">
          Regional
        </ion-label>
        <ion-select
          :value="selectedRegional"
          @ionChange="selectedRegional = $event.target.value"
        >
          <ion-select-option
            v-for="reg in regionales"
            :value="reg"
            :key="reg._id"
          >
            {{ reg.nombre }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </ion-col>
    <ion-col v-if="filtrarPor === 'Nodo'">
      <ion-item lines="full">
        <ion-label position="floating" class="ion-margin-top">
          Nodo
        </ion-label>
        <ion-select
          :value="selectedNodo"
          @ionChange="selectedNodo = $event.target.value"
        >
          <ion-select-option v-for="n in nodos" :value="n" :key="n">
            {{ n }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </ion-col>
  </ion-row>
  <ion-row class="ion-align-items-center">
    <ion-col>
      <ion-item lines="full">
        <ion-label position="floating" class="ion-margin-top">
          Campañas
        </ion-label>
        <ion-select
          :value="selectedCampania"
          @ionChange="selectedCampania = $event.target.value"
        >
          <ion-select-option
            v-for="camp in campanias"
            :value="camp"
            :key="camp._id"
          >
            {{ camp.nombre }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </ion-col>
    <ion-col size="auto">
      <ion-button color="tertiary" @click="updateChart1">
        Ver
      </ion-button>
    </ion-col>
  </ion-row>
  <div v-if="selectedCampania && displayChart1 && isData(cultivos)">
    <BarChart
      titulo="Área Sembrada por Cultivo"
      :labels="cultivos"
      :dataLabel="dataLabel"
      :dataValues="dataValues"
      chartIdNumber="1"
      backgroundColor="rgba(0, 192, 255, 0.58)"
    />
  </div>
  <div v-if="displayChart1 && !isData(cultivos)">
    <ion-text style="margin-top: 16px; margin-left: 16px">
      No hay Cultivos coincidentes con la búsqueda
    </ion-text>
  </div>
  <ion-row
    class="ion-align-items-center"
    v-if="displayChart1 && isData(cultivos)"
  >
    <ion-col>
      <ion-item lines="full">
        <ion-label position="floating" class="ion-margin-top">
          Cultivos
        </ion-label>
        <ion-select
          :value="selectedCultivo"
          @ionChange="selectedCultivo = $event.target.value"
        >
          <ion-select-option
            v-for="cultVar in cultivoVariedades"
            :value="cultVar"
            :key="cultVar.nombre"
          >
            {{ cultVar.nombre }}
          </ion-select-option>
        </ion-select>
      </ion-item>
    </ion-col>
    <ion-col size="auto">
      <ion-button color="tertiary" @click="updateChart2">
        Ver
      </ion-button>
    </ion-col>
  </ion-row>
  <div v-if="selectedCultivo && displayChart2 && isData(variedades)">
    <BarChart
      :titulo="'Área Sembrada por Variedades de ' + selectedCultivo.nombre"
      :labels="variedades"
      :dataLabel="dataLabel"
      :dataValues="dataValues2"
      chartIdNumber="2"
      backgroundColor="rgba(56, 192, 200, 0.58)"
    />
  </div>
  <v-if v-if="displayChart2 && !isData(variedades)">
    <ion-text style="margin-top: 16px; margin-left: 16px">
      No hay Variedades coincidentes con la búsqueda
    </ion-text>
  </v-if>
  <ion-button
    @click="volver"
    class="ion-margin-top ion-float-end ion-margin-bottom"
    color="primary"
    v-if="!isAdminAapresid"
  >
    Volver
  </ion-button>
  <ion-row class="ion-align-items-center ion-margin-top" v-if="isAdminAapresid">
    <ion-col>
      <ion-button @click="generateExcel" class="ion-float-start" fill="clear">
        <ion-icon
          :md="icons.excel"
          :ios="icons.excel"
          color="secondary"
          size="large"
        ></ion-icon>
      </ion-button>
    </ion-col>
    <ion-col size="auto">
      <ion-button @click="volver" class="ion-float-end" color="primary">
        Volver
      </ion-button>
    </ion-col>
  </ion-row>
</template>

<script>
import {
  IonRow,
  IonCol,
  IonRadio,
  IonRadioGroup,
  IonItem,
  IonLabel,
  IonButton,
  alertController,
  IonSelect,
  IonSelectOption,
  IonText,
  IonIcon
} from "@ionic/vue";
import { mapGetters, mapState } from "vuex";
import http from "@/api/http";
import { Regionales, Campanias } from "@/api";
import BarChart from "@/components/charts/BarChart.vue";
import dateFormat from "@/utils/dateFormat";
import { readerOutline } from "ionicons/icons";

export default {
  components: {
    IonItem,
    IonButton,
    IonRadio,
    IonRadioGroup,
    IonRow,
    IonCol,
    IonLabel,
    IonText,
    IonSelect,
    IonSelectOption,
    BarChart,
    IonIcon
  },
  middleware: ["authenticated", "auth_cliente"],
  data: () => ({
    selectedRegional: { _id: "0", nombre: "" },
    selectedNodo: "",
    selectedCampania: undefined,
    selectedCultivo: undefined,
    filtrarPor: "Regional",
    displayChart1: false,
    displayChart2: false,
    dataLabel: "Hectáreas",
    dataValues: [],
    dataValues2: [],
    cultivoVariedades: [],
    regionales: [],
    campanias: [],
    cultivos: [],
    variedades: [],
    nodos: [
      "Todos",
      "Litoral",
      "Centro",
      "Sur",
      "O.M.",
      "Oeste",
      "Internacional"
    ],
    icons: {
      excel: readerOutline
    }
  }),

  computed: {
    ...mapGetters(["isAdmin"]),
    ...mapState(["admin_user"]),
    isAdminAapresid() {
      return this.isAdmin && this.admin_user.roles[0] === "ADMIN_AAPRESID";
    }
  },

  watch: {
    selectedRegional(val) {
      if (val.nombre !== "") {
        this.displayChart2 = false;
        this.selectedCultivo = undefined;
      }
    },
    selectedNodo(val) {
      if (val !== "") {
        this.displayChart2 = false;
        this.selectedCultivo = undefined;
      }
    },
    filtrarPor() {
      this.selectedRegional = { _id: "0", nombre: "" };
      this.selectedNodo = "";
    },
    selectedCampania() {
      this.displayChart2 = false;
      this.selectedCultivo = undefined;
    }
  },

  async mounted() {
    await this.dataSync();
  },

  methods: {
    async dataSync() {
      this.regionales = await this.getRegionales();
      this.campanias = await this.getCampanias();
      this.$nextTick(() => {
        this.selectedCampania = this.campanias.find(c => c.esActual === true);
      });
    },

    async updateChart1() {
      if (this.selectedCampania) {
        const {
          labels,
          values,
          cultivoVariedades
        } = await this.getBarChart1Data();
        this.cultivos = labels;
        this.dataValues = values;
        this.cultivoVariedades = cultivoVariedades.filter(
          cv => cv.cultivo !== "Otros"
        );
        this.displayChart1 = true;
        this.displayChart2 = false;
      } else {
        const alert = await alertController.create({
          header: "Error",
          message: "Debe elegir una Campaña para poder continuar.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
    },

    async updateChart2() {
      if (this.selectedCultivo) {
        const variedades = this.selectedCultivo.variedades.map(cv => {
          return {
            nombre: cv.nombre !== "-" ? cv.nombre : "Otros",
            hectareas: cv.hectareas
          };
        });

        let cultsVarGroup = [];
        variedades.reduce((res1, value) => {
          if (!res1[value.nombre]) {
            res1[value.nombre] = {
              nombre: value.nombre,
              hectareas: 0
            };
            cultsVarGroup.push(res1[value.nombre]);
          }
          res1[value.nombre].hectareas += value.hectareas;
          return res1;
        }, {});

        this.variedades = cultsVarGroup.map(v => v.nombre);
        this.dataValues2 = cultsVarGroup.map(v => v.hectareas);

        this.displayChart2 = true;
      } else {
        const alert = await alertController.create({
          header: "Error",
          message: "Debe elegir un Cultivo para poder continuar.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      }
    },

    async getRegionales() {
      const { data, error } = await Regionales.get({
        sort: { nombre: 1 }
      });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo regionales.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        return alert.present();
      }
      data.unshift({ _id: "0", nombre: "Todas" });
      return data;
    },

    async getCampanias() {
      const { data, error } = await Campanias.get({
        sort: { nombre: 1 }
      });
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error obteniendo Campañas.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        return alert.present();
      }
      return data;
    },

    async generateExcel() {
      if (!this.selectedCampania) {
        const alert = await alertController.create({
          header: "Error",
          message: "Debe elegir una Campaña para poder continuar.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        alert.present();
      } else {
        const { data } = await http.get("/api/area-sembrada-get-xlsx", {
          params: {
            campania: this.selectedCampania,
            regional: { _id: "0", nombre: "" },
            nodo: "Todos",
            cultivo: undefined
          }
        });
        const mimeType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const campaniaTxt = this.selectedCampania
          ? this.selectedCampania.nombre + "_"
          : "";
        const fileName =
          "Informe_Area_Sembrada_" +
          campaniaTxt +
          "-_" +
          dateFormat(new Date(), "dd-MM-yyyy") +
          "_-_" +
          dateFormat(new Date(), "HH-mm") +
          ".xlsx";
        fetch(`data:${mimeType};base64,${data}`)
          .then(res => res.blob())
          .then(blob => new File([blob], fileName, { type: mimeType }))
          .then(doc => {
            const url = (window.URL || window.webkitURL).createObjectURL(doc);
            let a = document.createElement("a");
            a.href = url;
            a.download = fileName;
            a.dataset.downloadurl = `${mimeType}:${a.download}:${a.href}`;
            document.body.appendChild(a);
            a.click();
            a.remove();
            return url;
          });
      }
    },

    async getBarChart1Data() {
      const { data, error } = await http.get(
        "/api/area-sembrada-cultivo-ha-data",
        {
          params: {
            campania: this.selectedCampania,
            regional: this.selectedRegional,
            nodo: this.selectedNodo
          }
        }
      );
      if (error) {
        const alert = await alertController.create({
          header: "Error",
          message: "Error calculando las Hectáreas por Cultivo.",
          buttons: [
            {
              text: "Aceptar"
            }
          ]
        });
        return alert.present();
      }
      const labels = data.map(d => d.nombre);
      const values = data.map(d => d.hectareas);
      const cultivoVariedades = data.map(d => {
        return { nombre: d.nombre, variedades: d.variedades };
      });
      return { labels, values, cultivoVariedades };
    },

    isData(data) {
      return data.length ? true : false;
    },

    volver() {
      this.$router.back();
    }
  }
};
</script>
